




























































































import { Component, Vue, Watch } from 'vue-property-decorator'
import GroupService from '@/services/GroupService'
import NavCommonTopicCount from '@/components/NavCommonTopicCount.vue'

import { getModule } from 'vuex-module-decorators'
import CommonTopic from '@/store/modules/CommonTopic'
import store from '@/store'
import { GroupOrder } from '@/models'

const CommonTopicModule = getModule(CommonTopic, store)

@Component({
  components: {
    NavCommonTopicCount
  }
})
export default class NavGroupOrder extends Vue {
  private groupsOrder: GroupOrder[] = []
  private page: number = 1
  private avatarGroupDefault = require('@/assets/images/group/avatar_default.png')

  created() {
    this.getGroupOrder()
  }

  // @Watch('$store.state.commonTopic.reloadUnread')
  getGroupOrder() {
    let limit = 999999
    GroupService.getGroupOrder(this.page, limit)
      .then(res => {
        if (res.status === 200) {
          this.groupsOrder = res.data.data
        }
      })
      .catch(err => {
        console.log(err)
      })

    //handle rebind unred count
    CommonTopicModule.SET_RELOAD_UNREAD(false)
  }
}
